export default [
  { key: 'recently_listed', value: 'recentlyListed' },
  { key: 'recently_created', value: 'recentlyCreated' },
  // { key: 'recently_sold', value: 'recentlySold' },
  // { key: 'ending_soon', value: 'endingSoon' },
  { key: 'cheapest', value: 'cheapest' },
  // { key: 'most_stared', value: 'mostStarted' },
  { key: 'oldest', value: 'oldest' },
  { key: 'pledge', value: 'pledge' },
];
